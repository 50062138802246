
























import { Component, Vue } from 'vue-property-decorator'
import QRCode from 'qrcode'
import { Account } from '@/api'
import { UserModule } from '@/store/modules'

@Component({
  components: {
    Backup2FACodes: () => import('./Backup2FACodes.vue')
  }
})
export default class Enable2FA extends Vue {
  @UserModule.Action getUser

  showModal = false
  backupCodes: string[] = []
  code = ''
  qrCodeInfos = null as any

  async mounted() {
    try {
      const { secret, uri } = await Account.getQRCodeInfos()
      const QRCodeURI = await QRCode.toDataURL(uri)

      this.qrCodeInfos = {
        secret,
        uri: QRCodeURI
      }
    } catch(e) {
      console.error('Error when trying to get QR Code Infos', e)
    }
  }

  async enable2FA() {
    try {
      const { backupCodes } = await Account.enable2FA(this.code)

      this.$root.$emit('show2FACodes', backupCodes)
      this.code = ''
      await this.getUser()
      this.showModal = false
    } catch(e) {
      console.error('Error when trying to enable 2FA', e)
    }
  }
}
